import React from "react"
import "../../works/vr-restaurant.css"
import Footer from "../../../components/footer"
import WorkHeader from "../../../components/workHeader"
import ehlvr from "../../../../static/work_headers/ehlvr.png"
import Dict from "../../../langs/dict"
import WorkAbout from "../../../components/workAbout"
import Perk from "../../../components/perks"
import ehlvr1 from "../../../../static/works_perks/ehlvr_2.png"
import ehlvr2 from "../../../../static/works_perks/ehlvr_1.png"
import Contact from "../../../components/contact"
import ehlLogo from "../../../../static/logos/ehl_logo.png"
import Layout from "../../../components/layoutWorks"

export default () => {
  const lang = "fr"
  const dict = new Dict(lang)
  return (
    <Layout
      seoTitle="Site Web - EHL"
      seoDescription="Pour ce mandat, webpanda a utilisé la technologie de VR pour le web appellée WebVR. Elle permet d'immerger l'utilisateur du site grâce à n'importe quel smartphone et de lunettes VR en carton."
    >
      <div id="works-vrestaurant-background">
        <WorkHeader
          title={dict.getTrans("work_ehlvr_title")}
          subTitle={dict.getTrans("work_ehlvr_subtitle")}
          pic={ehlvr}
          navLang={lang}
          hl="#46838F"
          pageUrl="/works/vr-restaurant"
        />
      </div>
      <WorkAbout
        title={dict.getTrans("ehlvr_about_title")}
        p1={dict.getTrans("ehlvr_about_p1")}
        p2={dict.getTrans("ehlvr_about_p2")}
        logo={ehlLogo}
      />
      <div id="works-vrestaurant-perks-holder">
        <Perk
          title={dict.getTrans("ehlvr_perk1_title")}
          image={ehlvr1}
          text={dict.getTrans("ehlvr_perk1_text")}
        />
        <Perk
          title={dict.getTrans("ehlvr_perk2_title")}
          image={ehlvr2}
          text={dict.getTrans("ehlvr_perk2_text")}
        />
      </div>
      <div id="works-tft-contact">
        <Contact lang="en" />
      </div>
      <Footer lang={lang} />
    </Layout>
  )
}
